<template>
    <section class="content">
        <table class="table table-hover" ref="tableuser">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Username</th>
                    <th>Email</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
            </tbody>
        </table>
        <div class="card">
            <div class="card-body">
                <button class="btn btn-default" v-on:click="addItem">Tambah item</button>
                <button class="btn btn-default" v-on:click="simpanData">Simpan Data</button>
            </div>
        </div>
    </section>
    <!-- /.content -->
</template>
<script>
import { createTable } from '@/libs/hxcore';
import $ from 'jquery';

//var tbl = null;
export default {
    name: 'Table',
    methods: {
        addItem: function() {
            var nRow = this.tbl.fnAddRow('<div class="btn-group"><a class="btn edit btn-xs icon-edit btn-primary"><i class="fas fa-pen"></i> Edit</a><a class="btn delete btn-xs icon-remove btn-danger"><i class="fa fa-trash"></i> Delete</a></div>');
            $('td .btn.edit', nRow).click();
        },        
        simpanData: function() {
            var data = this.tbl.getSerialize();
            console.log(data)
        }
    },
    mounted() {
        const e = this.$refs;
        this.tbl = createTable(e.tableuser, {
            "title": "Tabel Member",
            serverSide: false,
            "ajax": "/examples/transient_data",
            "columns": [
                { "data": "id" },
                { "data": "fullname" },
                { "data": "surname" },
                { "data": "email" },
                { "data": "action"}
                /*{
                    "orderable":      false,
                    "data":           null,
                    type: 'action',
                    "defaultContent": '-'
                },*/
            ]
        }).fnEditor({
            action: '<div class="btn-group"><a class="btn edit btn-xs icon-edit btn-primary"><i class="fas fa-pen"></i> Edit</a><a class="btn delete btn-xs icon-remove btn-danger"><i class="fa fa-trash"></i> Hapus</a></div>'
        })
    }
}
</script>